import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image";
import styles from "./compra.module.css";
import formStyles from "./form.module.css";
import PrimaryButton from "../components/primary-button";
import { useFormInput } from "../hooks/form-input";
import { getProvinces, makePayment } from "../services/pymes-service";
import { MultiSelect } from "../components/multi-select";
import StripeElements from "../components/stripe-elements/container/StripeElements";
import InjectedCardForm from "../components/stripe-elements/compoments/injected-card-form/InjectedCardForm";

const BuyPage = ({ data }) => {
  // getting provinces
  const [provinces, setProvinces] = useState('');

  useEffect(() => {
    getProvinces().then((items) => {
      const provinceElements = items.map(i => <option key={i.id} value={`${i.code}-${i.name}`}>{i.name}</option>);
      setProvinces(provinceElements);
    });
  }, []);

  // budget controller
  const [budgetInfo, setBudgetInfo] = useState('La cantidad de impresiones dependerá del presupuesto.');

  const getBudgetInfo = (budget) => {
    switch (true) {
      case budget === '600':
        return 'La cantidad de impresiones sería 500.000 (CPM: 1,20€)'
      case budget === '1000':
        return 'La cantidad de impresiones sería 1.111.111 (CPM: 1,00€)'
      case budget === '2000':
        return 'La cantidad de impresiones sería 2.352.941 (CPM: 1,00€)'
      default:
        return 'La cantidad de impresiones dependerá del presupuesto.'
    }
  }

  // segmentation & budget controller
  const [segmentation, setSegmentation] = useState([]);

  const communities = [
    { checked: false, value: 'Nacional' },
    { checked: false, value: 'Andalucía' },
    { checked: false, value: 'Aragón' },
    { checked: false, value: 'Principado de Asturias' },
    { checked: false, value: 'Illes Balears' },
    { checked: false, value: 'Canarias' },
    { checked: false, value: 'Cantabria' },
    { checked: false, value: 'Castilla y León' },
    { checked: false, value: 'Castilla La Mancha' },
    { checked: false, value: 'Cataluña' },
    { checked: false, value: 'Comunidad Valenciana' },
    { checked: false, value: 'Extremadura' },
    { checked: false, value: 'Galicia' },
    { checked: false, value: 'Comunidad de Madrid' },
    { checked: false, value: 'Región de Murcia' },
    { checked: false, value: 'Comunidad Foral de Navarra' },
    { checked: false, value: 'País Vasco' },
    { checked: false, value: 'La Rioja' },
    { checked: false, value: 'Ciudad Autónoma de Ceuta' },
    { checked: false, value: 'Ciudad Autónoma de Melilla' },
  ]

  const handleSegmentation = (audience) => {
    setSegmentation(audience)
    if (audience[0] === 'Nacional' && audience.length !== 1) {
      setErrors({
        ...errors,
        segmentation: 'Debe elegir entre Nacional o CCAA',
      })
    } else {
      clearError('segmentation')
    }
  }

  const budget = useFormInput(null, 'radio', (e) => {
    const value = e.target.value;
    setBudgetInfo(getBudgetInfo(value))
    if (value === '2000' && duration.value === '2') {
      duration.setValue(null);
    }
  })
  const duration = useFormInput('3', 'radio')
  const [banners, setBanners] = useState({})
  const [errors, setErrors] = useState({})
  const clearError = (key) => {
    const e = { ...errors }
    delete e[key]
    setErrors(e)
  }
  const validRequires = () => {
    let valid = true;
    if (segmentation.length === 0) {
      valid = false;
      setErrors({
        ...errors,
        segmentation: 'Debe seleccionar al menos una.'
      })
    }

    return valid
  }

  // file controller
  const handleFile = (e) => {
    const file = e.target.files[0]
    const name = e.target.name;

    setBanners({
      ...banners,
      [name]: file,
    })
    clearError(name)

    if (!file) {
      return;
    }

    if (file && file.size > 100000) {
      setErrors({
        ...errors,
        [name]: `Tamaño inválido (${(file.size / 1000).toFixed(2)}kB)`,
      })

      return;
    }

    const url = URL.createObjectURL(file);
    const img = new Image();

    img.onload = function () {
      const [width, height] = name.split('x');
      if (img.width !== Number(width) || img.height !== Number(height)) {
        setErrors({
          ...errors,
          [name]: `Dimensiones inválidas (${img.width}x${img.height})`
        });
        return;
      }
      URL.revokeObjectURL(img.src);
    };
    img.src = url;
  }

  const url = useFormInput('', 'text', (e) => {
    if (!e.target.value.startsWith('https://') && !e.target.value.startsWith('http://')) {
      setErrors({
        ...errors,
        [e.target.name]: 'Debe agregar https:// o http://'
      })

    } else {
      clearError(e.target.name);
    }
  });
  const company = useFormInput('');
  const address = useFormInput('');
  const province = useFormInput('');
  const city = useFormInput('');
  const postalCode = useFormInput('');
  const vat = useFormInput('', 'text', (e) => {
    const validVat = new RegExp(/^(X(-|\.)?0?\d{7}(-|\.)?[A-Z]|[A-Z](-|\.)?\d{7}(-|\.)?[0-9A-Z]|\d{8}(-|\.)?[A-Z])$/)
    if (!validVat.test(e.target.value)) {
      setErrors({
        ...errors,
        vat: 'CIF inválido',
      })
    } else {
      clearError(e.target.name)
    }
  });
  const firstName = useFormInput('');
  const lastName = useFormInput('');
  const email = useFormInput('');
  const phone = useFormInput('');
  const cardNumber = useFormInput('')
  const cardExpirationMonth = useFormInput('')
  const cardExpirationYear = useFormInput('')
  const cardCvc = useFormInput('')
  const terms = useFormInput(false, 'checkbox');

  const inputs = [
    budget,
    duration,
    url,
    company,
    address,
    province,
    city,
    postalCode,
    vat,
    firstName,
    lastName,
    email,
    phone,
    cardNumber,
    cardExpirationMonth,
    cardExpirationYear,
    cardCvc,
    terms,
  ];
  const [message, setMessage] = useState('');
  const [sending, setSending] = useState(false);
  const [financialData, setFinancialData] = useState(null);
  const [editCard, setEditCard] = useState(true);

  const formSending = (value) => {
    setSending(value);
    inputs.forEach(i => i.setDisabled(value));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validRequires() || Object.keys(errors).length > 0) {
      setMessage('Errores en el formulario')
      return;
    }
    formSending(true);
    setMessage('Enviando...')
    makePayment({
      audience: segmentation,
      budget: Number(budget.value),
      duration: Number(duration.value),
      banners,
      url: url.value,
      company: company.value,
      address: address.value,
      province: province.value.slice(3),
      provinceId: Number(province.value.slice(0, 2)),
      city: city.value,
      postalCode: postalCode.value,
      vat: vat.value,
      firstName: firstName.value,
      lastName: lastName.value,
      email: email.value,
      phone: phone.value,
      cardSource: financialData.cardSource,
    })
      .then(() => {
        inputs.forEach(i => i.setDefault());
        setMessage('Hemos enviado a su correo un enlace para confirmar la compra. Recuerde revisar su bandeja de spam.')
      })
      .catch((e) => {
        setMessage('Error al enviar la información. Vuelva a intentarlo en unos instantes, si el problema persiste pongase en contacto con nosotros.')
        console.error(e.message)
      })
      .finally(() => formSending(false))
  };

  const handleCard = (paymentData) => {
    setFinancialData({
      cardSource: paymentData.cardSource,
      exp_year: paymentData.paymentMethod.card.exp_year,
      exp_month: paymentData.paymentMethod.card.exp_month,
      last4: paymentData.paymentMethod.card.last4
    });

    setEditCard(false);
  }

  return (
    <Layout>
      <SEO title="Contrata ahora tu campaña de banners" />
      <Img
        className={styles.mainImage}
        fluid={[
          {
            ...data.DesktopImacImage.childImageSharp.fluid,
            media: `(min-width: 1024px)`,
          },
          {
            ...data.ipaqImacImage.childImageSharp.fluid,
            media: `(min-width: 768px)`,
          },
          data.mobileImacImage.childImageSharp.fluid,
        ]} />
      <article className={styles.article}>
        <h1>
          <span>Contrata ahora</span>
          <span>Tu campaña de banners</span>
          <span className={styles.titleLine} />
        </h1>
        <form className={formStyles.form} onSubmit={handleSubmit}>
          <section>
            <h3>1. Define la zona geográfica</h3>
            <div className={formStyles.twoColumns}>
              Segmentación
            </div>
            <MultiSelect options={communities} onChange={handleSegmentation} />
            {errors.segmentation ? <div className={formStyles.inputError}>{errors.segmentation}</div> : ''}
          </section>

          <section>
            <h3>2. Elige tu presupuesto</h3>
            <div className={formStyles.twoColumns}>
              <div>
                <label>
                  <div><input value="600" required type="radio" name="budget"
                    {...budget.input}
                    checked={budget.value === '600'} /> 600€ + IVA
                  </div>
                </label>
                <label>
                  <div><input value="1000" required type="radio" name="budget"
                    {...budget.input}
                    checked={budget.value === '1000'} /> 1.000€ + IVA
                  </div>
                </label>
                <label>
                  <div><input value="2000" required type="radio" name="budget"
                    {...budget.input}
                    checked={budget.value === '2000'} /> 2.000€ + IVA
                  </div>
                </label>
              </div>
              <p>{budgetInfo}</p>
            </div>
          </section>

          <section>
            <h3>3. Establece la duración de la campaña</h3>
            <div className={formStyles.twoColumns}>
              <div>
                <label>
                  <div><input required type="radio" name="duration" value="4"
                    {...duration.input}
                    checked={duration.value === '4'} /> 4 semanas
                  </div>
                </label>
                <label>
                  <div><input required type="radio" name="duration" value="8"
                    {...duration.input}
                    disabled={budget.value === '600'}
                    checked={duration.value === '8'} /> 8 semanas
                  </div>
                </label>
              </div>
              <p>
                La campaña se lanzará aproximadamente 24 horas laborables después de confirmarse el pago.
              </p>
            </div>
          </section>

          <section>
            <h3>4. Sube tus banners y la url de destino</h3>
            <p>Debes elegir al menos uno de los dos tamaños, sin embargo lo mejor sería subir ambos tamaños. Máximo
              100kB</p>
            <label>
              300x600 Formato .JPG, .GIF o .PNG
              <input accept="image/*" required={banners["980x250"] === undefined} name="300x600" onChange={handleFile}
                type="file" />
              {errors["300x600"] ? <div className={formStyles.inputError}>{errors["300x600"]}</div> : ''}
            </label>
            <label>
              980x250 Formato .JPG, .GIF o .PNG
              <input accept="image/*" required={banners["300x600"] === undefined} name="980x250" onChange={handleFile}
                type="file" />
              {errors["980x250"] ? <div className={formStyles.inputError}>{errors["980x250"]}</div> : ''}
            </label>
            <label>
              URL de destino
              <input required name="url" placeholder="https://www.tuwebaqui.com" {...url.input} type="url" />
              {errors.url ? <div className={formStyles.inputError}>{errors.url}</div> : ''}
            </label>
          </section>

          <section>
            <h3>5. Rellena tus datos de facturación</h3>
            <label>
              <span>RAZÓN SOCIAL (*)</span>
              <input required type="text" name="company" {...company.input} />
            </label>
            <label>
              <span>DOMICILIO SOCIAL (*)</span>
              <input required type="text" name="address" {...address.input} />
            </label>
            <label>
              <span>PROVINCIA (*)</span>
              <select name="province" required {...province.input}>
                <option value="">seleccione...</option>
                {provinces}
              </select>
            </label>
            <label>
              <span>CIUDAD (*)</span>
              <input type="text" name="city" required {...city.input} />
            </label>
            <label>
              <span>CÓDIGO POSTAL (*)</span>
              <input type="text" name="postalCode" required {...postalCode.input} />
            </label>
            <label>
              <span>CIF (*)</span>
              <input required type="text" name="vat" placeholder="B12345670" minLength="9"
                maxLength="9" {...vat.input} />
              {errors.vat ? <div className={formStyles.inputError}>{errors.vat}</div> : ''}
            </label>
            <label>
              <span>NOMBRE DE CONTACTO(*)</span>
              <input required type="text" name="firstName" {...firstName.input} />
            </label>
            <label>
              <span>APELLIDOS DE CONTACTO (*)</span>
              <input required type="text" name="lastName" {...lastName.input} />
            </label>
            <label>
              <span>CORREO ELECTRÓNICO (*)</span>
              <input required type="email" name="email" {...email.input} />
            </label>
            <label>
              <span>TELÉFONO MÓVIL (*)</span>
              <input required type="tel" name="phone" minLength="9" maxLength="9"
                placeholder="612345678" {...phone.input} />
            </label>
          </section>

          <section className={styles.card}>
            <h3>6. Datos de pago</h3>
            {editCard && (
              <StripeElements>
                <InjectedCardForm onCardCreated={handleCard} />
              </StripeElements>
            )}

            {!editCard && (
              <div className={styles.cardSelected}>
                <>
                  <div className={styles.editFinancial}>
                    <span className={styles.editBtn} onClick={() => setEditCard(true) }>Cambiar</span>
                  </div>
                  <div>Tarjeta xxxx xxxx xxxx <span className={styles.last4}>{financialData.last4}</span></div>
                  <br />
                  <div>Expiración <span className={styles.date}>{financialData.exp_month} / {financialData.exp_year}</span></div>
                </>
              </div>
            )}

          </section>
          <section>
            <label>
              <div>
                <input required type="checkbox" {...terms.input} />
                <div>
                  Acepto el <a target="_blank" rel="noreferrer" href="https://www.eldiario.es/aviso_legal/">
                    Aviso Legal
                  </a> la <a target="_blank" rel="noreferrer" href="https://www.eldiario.es/privacidad/">
                    Política de privacidad
                  </a>
                </div>
              </div>
            </label>
            <p>Enviaremos un correo con un enlace para confirmar el pago. Al momento de abrir el enlace realizaremos el
              cargo en su tarjeta.</p>
            <PrimaryButton type="submit" disabled={sending}>CONTRATAR AHORA</PrimaryButton>
          </section>
        </form>
        {
          message ?
            message.startsWith('Error') ?
              <p className={[formStyles.message, formStyles.error].join(' ')}>{message}</p> :
              <p className={[formStyles.message, formStyles.ok].join(' ')}>{message}</p> :
            ''
        }
      </article>
      <div className={styles.bottomImage}>
        <h2>
          <span>Potencia tu marca y </span>
          aumenta tus ventas
          <span className={styles.titleLine} />
        </h2>
        <Img
          style={{ width: '100%' }}
          fixed={[
            {
              ...data.desktopDevicesImage.childImageSharp.fixed,
              media: `(min-width: 1024px)`,
            },
            {
              ...data.ipaqDevicesImage.childImageSharp.fixed,
              media: `(min-width: 768px)`,
            },
            data.mobileDevicesImage.childImageSharp.fixed,
          ]}
        />
      </div>
    </Layout>
  );
}

export default BuyPage

export const query = graphql`
    query {
      mobileImacImage: file(relativePath: { eq: "imac.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 255, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ipaqImacImage: file(relativePath: { eq: "imac-ipaq.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      DesktopImacImage: file(relativePath: { eq: "imac-desktop.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mobileDevicesImage: file(relativePath: { eq: "devices.jpg" }) {
        childImageSharp {
          fixed(height: 290, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      ipaqDevicesImage: file(relativePath: { eq: "devices.jpg" }) {
        childImageSharp {
          fixed(height: 450, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      desktopDevicesImage: file(relativePath: { eq: "devices.jpg" }) {
        childImageSharp {
          fixed(height: 928, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `
