import React from 'react';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { environment } from '../../../environments/environment';

const stripePromise = loadStripe(environment.stripeApiKey);

function StripeElements({ children }) {

  return (
    <>
      <Elements stripe={stripePromise} options={null}>
        {children}
      </Elements>
    </>
  )
}

export default StripeElements