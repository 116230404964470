import React, {useState} from "react";
import styles from "./multi-select.module.css";

export const MultiSelect = props => {
  const [selectState, setSelectState] = useState(props.options);

  const handleChange = (e) => {
    const selectedValues = [];
    const options = [...selectState];

    for (const option of options) {
      if (option.value === e.target.value) {
        option.checked = e.target.checked
      }

      if (option.checked) {
        selectedValues.push(option.value);
      }
    }

    setSelectState(options)

    props.onChange(selectedValues)
  }

  return (
    <div className={styles.select}>
      <div className={styles.selectOptions}>
        {
          selectState.map((option, id) => (
            <label key={id}>
              <input type="checkbox" value={option.value} checked={option.checked} onChange={handleChange}/>
              {option.value}
            </label>))
        }
      </div>
    </div>
  );
};
