
import React from 'react';
import { ElementsConsumer, CardElement } from '@stripe/react-stripe-js';
import './InjectedCardForm.css';
import { useState } from 'react';

const CardCheckoutForm = ({ stripe, elements, onCardCreated }) => {

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {

    try {

      event.preventDefault();

      if (!stripe || !elements) {
        console.error('Stripe.js has not loaded yet.');
        return;
      }

      const card = elements.getElement(CardElement);

      if (card == null) {
        return;
      }

      const { token } = await stripe.createToken(card);

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card,
      });

      if(!error && error !== null) setError(null);
      
      if (error) {
        console.error('Error creating payment method:', error);
        const { message } = error;

        if (message) {
          setError(message);
        }

        return;
      }

      onCardCreated({
        paymentMethod: paymentMethod,
        cardSource: token.id,
      });

    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  return (
    <div>
      <CardElement
        className={`${'card-element'} ${error ? 'error' : ''}`}
        options={{
          style: {
            base: {
              fontSize: '16px',
              fontFamily: "'Encode Sans', sans-serif",
              color: 'rgba(0, 0, 0, 0.5)',
              '::placeholder': {
                color: '#8E8E93',
              },
            },
          },
          hidePostalCode: true,
        }}
      />
      {error && (
        <div className={'error-container'}>
          <span className={'stripe-error'}>
            {error}
          </span>
        </div>
      )}

      <button disabled={loading || !stripe} className={'btn-submit'} onClick={handleSubmit}>
         Guardar método de pago
      </button>
    </div>
  );
}

export default function InjectedCardForm({ onCardCreated }) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CardCheckoutForm stripe={stripe} elements={elements} onCardCreated={onCardCreated} />
      )}
    </ElementsConsumer>
  )
}